import { Controller } from "@hotwired/stimulus"
import { blobToFile, directUpload, loadingOverlay, mod } from "../lib/utils"
import $ from "jquery"
import { post } from "@rails/request.js"

const ORIENTATIONS = ["portrait", "landscape"]

export default class extends Controller {
  static values = { bookUuid: String }
  static targets = ["croppieContainer", "orientationContainer", "zoomLabel", "rotateLabel"]

  declare bookUuidValue: string

  declare readonly croppieContainerTarget: HTMLElement
  declare readonly orientationContainerTarget: HTMLElement
  declare readonly zoomLabelTarget: HTMLElement
  declare readonly rotateLabelTarget: HTMLElement

  selectedOrientation = 0
  imageUrl: string | ArrayBuffer

  connect() {
    this._setOrientation(this.selectedOrientation)
  }

  fileChanged(event) {
    const file = event.currentTarget.files[0]
    if (!file) {
      return
    }

    const fileExtension = file.name.replace(/^.*\./, "")
    if (!["jpg", "jpeg", "png", "svg"].includes(fileExtension.toLocaleLowerCase())) {
      alert("Not valid extension. Supported Extensions are (.png .svg .jpg .jpeg)")
      return
    }

    const file_reader = new FileReader()
    file_reader.readAsDataURL(file)
    file_reader.onload = (event) => {
      this._cropper().croppie("bind", {
        url: event.target.result,
      })
      this.imageUrl = event.target.result
    }
    event.currentTarget.value = null
    this.element.querySelector(".crop-options").classList.add("active")
  }

  rotate(event) {
    if (event.currentTarget.value > 0) {
      this._cropper().croppie("rotate", -90)
    } else {
      this._cropper().croppie("rotate", 90)
    }
  }

  saveUpload() {
    const size = "{150,150}"

    this._cropper()
      .croppie("result", { type: "blob", size, format: "jpeg" })
      .then(async (canvasData) => {
        loadingOverlay.show()
        try {
          const blob = await directUpload(blobToFile(canvasData), "photo")
          const uploadResult = await post(`/books/${this.bookUuidValue}/full-size-content`, {
            body: {
              asset: blob.signed_id,
              orientation: ORIENTATIONS[this.selectedOrientation],
            },
          })

          if (!uploadResult.ok) {
            alert("Error uploading file")
            return
          }

          this.element.dispatchEvent(
            new CustomEvent("addAsset", {
              bubbles: true,
              detail: { result: uploadResult },
            }),
          )
        } catch (error) {
          alert(error)
        } finally {
          loadingOverlay.hide()
          this._cropper().croppie("destroy")
          this.element.querySelector(".crop-options").classList.remove("active")
        }
      })
  }

  _setOrientation(index) {
    this.selectedOrientation = mod(index, ORIENTATIONS.length)
    this.orientationContainerTarget.classList.remove(...ORIENTATIONS)
    this.orientationContainerTarget.classList.add(ORIENTATIONS[this.selectedOrientation])
    this.resetCropper()
  }

  _cropper() {
    if ($(this.croppieContainerTarget).data("croppie")) {
      return $(this.croppieContainerTarget)
    }
    return this.resetCropper()
  }

  resetCropper() {
    if ($(this.croppieContainerTarget).data("croppie")) {
      $(this.croppieContainerTarget).croppie("destroy")
    }

    if (ORIENTATIONS[this.selectedOrientation] === "portrait") {
      return this._initializeCropper(275, 390.6)
    } else if (ORIENTATIONS[this.selectedOrientation] === "landscape") {
      return this._initializeCropper(385.5, 275)
    }
  }

  _initializeCropper(width, height) {
    const cropper = $(this.croppieContainerTarget)
      .croppie({
        enableExif: true,
        viewport: {
          width: width,
          height: height,
        },
        boundary: { width: width + 100, height: height + 100 },
        enableOrientation: true,
        enforceBoundary: true,
      })
      .on("update.croppie", (ev, cropData) => {
        this.zoomLabelTarget.innerText = (Math.round(cropData.zoom * 100) / 100).toString()
        this.rotateLabelTarget.innerText = (90 * ((cropData.orientation - 1) % 4)).toString()
      })

    if (this.imageUrl) {
      cropper.croppie("bind", {
        url: this.imageUrl,
      })
    }
    return cropper
  }

  prevOrientation() {
    this._setOrientation(this.selectedOrientation - 1)
  }

  nextOrientation() {
    this._setOrientation(this.selectedOrientation + 1)
  }
}
