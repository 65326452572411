import { Modal } from "bootstrap"
import { DirectUpload } from "@rails/activestorage"

export function mod(n: number, modulus: number): number {
  return ((n % modulus) + modulus) % modulus;
}

export const breakpoints = {
  sm: 576,
  md: 768,
  lg: 992,
  xl: 1200,
  xxl: 1400
}

export function blobToFile(blob: Blob, fileName: string | undefined = undefined): File {
  const extensionByMimeType = {
    "image/jpeg": "jpg",
    "image/png": "png",
    "image/gif": "gif",
    "image/svg+xml": "svg",
    "image/webp": "webp",
    "audio/ogg": "ogg"
  }

  if (!fileName) {
    fileName = "blob"

    if (extensionByMimeType[blob.type]) {
      fileName += `.${extensionByMimeType[blob.type]}`
    }
  }

  return new File([blob], fileName, { type: blob.type });
}

let loadingOverlayTimer: number | null = null
export const loadingOverlay = {
  show: (delay: number = 0) => {
    loadingOverlayTimer = window.setTimeout(() => {
      const loadingOverlay = document.querySelector("dialog#loading-overlay") as HTMLDialogElement
      if (loadingOverlay) {
        loadingOverlay.showModal()
      }
    }, delay)
  },
  hide: () => {
    clearTimeout(loadingOverlayTimer)
    loadingOverlayTimer = null
    const loadingOverlay = document.querySelector("dialog#loading-overlay") as HTMLDialogElement
    if (loadingOverlay) {
      loadingOverlay.close()
    }
  }
}

export function openModalFromTemplate(modalHtml: string) {
  const template = document.createElement("template")
  template.innerHTML = modalHtml

  if (template.content.childElementCount != 1) {
    throw new Error("Modal must have exactly one root element")
  }

  const modalEl = template.content.firstElementChild
  modalEl.addEventListener("hidden.bs.modal", () => modalEl.remove())
  document.body.appendChild(modalEl)

  Modal.getOrCreateInstance(modalEl).show()

  return modalEl
}

export function onVisible(element: Element, callback: () => void) {
  const observer = new ResizeObserver((entries) => {
    for (const entry of entries) {
      if (entry.contentRect.width > 0 && entry.contentRect.height > 0) {
        callback()
        observer.disconnect()
      }
    }
  })
  observer.observe(element)
}

export async function directUpload(file: File, purpose) {
  const directUploadsUrl = (document.getElementsByName("direct-uploads-url")[0] as HTMLMetaElement).content

  return new Promise<{ signed_id: string }>((resolve, reject) => {
    new DirectUpload(file, `${directUploadsUrl}?purpose=${purpose}`).create((error, uploadResult) => {
      if (error) {
        reject(error)
      } else {
        resolve(uploadResult)
      }
    })
  })
}
