// Entry point for the build script in your package.json

import * as ActiveStorage from "@rails/activestorage"

import jQuery from "jquery"
window.$ = window.jQuery = jQuery
import "jquery-validation/dist/jquery.validate"

import "slick-carousel"
import { install } from "./lib/unsave"
document.addEventListener("DOMContentLoaded", install, false)

import { Modal } from "bootstrap"
import { loadingOverlay } from "./lib/utils"

ActiveStorage.start()

import "./controllers"

window.B4M = {
  showModal(element) {
    Modal.getOrCreateInstance(element).show()
  }
}

jQuery(document).ready(function () {
  $('#save_response_form').validate({
    submitHandler: function(form) {
      form.submit();
    }
  });

  $('#quiz-form').validate({
    rules:
      {
        'invite[name]': {
          required: true,
        }
      },
    errorPlacement: function(){
      return false;
    }
  });

  $("input[type='email']").on("focusout", function(event) {
    var emailField = $(this);
    var errorMessage = emailField.data("locale-message");

    setTimeout(function() {
      var errorLabel = $("#invite_email-error");
      errorLabel.text('');

      if (emailField[0].validity.typeMismatch) {
        errorLabel.text(errorMessage);
        errorLabel.show();
      } else {
        errorLabel.text('');
        errorLabel.hide();
      }
    }, 100);
  });

  $("body [data-submit-form]").on("click", function (event){
    if($('#quiz-form').valid()){
      $("#quiz-form").append(`<input type="hidden" name="invite[status]" value="Complete">`);
      if ($('#invite_email') && $('#invite_email').val().trim() !== ""){
        $("#quiz-form").append($('#invite_email'));
      }
      var params = $('#quiz-form').serialize();

      params += '&submit_only=1';
      $.ajax({
        type: 'POST',
        url: `/${document.documentElement.lang}/participant/save_response`,
        data: params,
        success: function(data) {
          if (data && data['uuid']){
            window.location.href = data['url']
          }
        },
        error: function(data) {
          return false;
        }
      });
    }
    else{
      alert(document.documentElement.lang === 'de' ? 'Alle rot markierten Felder sind Pflichtfelder und müssen ausgefüllt werden' : 'All fields are required');
    }
  });

  $("body [data-save-now-form]").on("click", function (event){
    for (const input of document.getElementById('quiz-form').elements) {
      $('<input>').attr({
        type: 'hidden',
        name: input.name,
        value: input.value
      }).appendTo('#save_response_form');
    }
    if(($('#invite_email').val()=='') && (!window.location.href.includes("uuid"))){
      $('#saveModal').modal('show');
    } else {
      $('#save_response_form').submit();
    }
  });

  $('.own-content-save').on('click', function() {
    if($('#own-content-form').valid()){
      var params = $('#own-content-form').serialize();

      params += '&submit_only=2';
      $.ajax({
        type: 'POST',
        url: `/${document.documentElement.lang}/participant/save_response`,
        data: params,
        success: function(data) {
          if (data && data['uuid']){
            window.location.href = data['url']
          }
        },
        error: function(data) {
          return false;
        }
      });
    }
  })

  $("#saveModal").on("hidden.bs.modal", function () {
    $("#invite_email").val('');
  });
});

$(document).on({
  ajaxStart: function(){
    loadingOverlay.show()
  },
  ajaxStop: function(){
    loadingOverlay.hide()
  }
});

// Workaround for Bootstrap modal z-index issue with multiple open modals
// Based on https://stackoverflow.com/a/25981907
// TODO: Re-evaluate this when Bootstrap switches to <dialog>-based modals
$(document).on({
  "show.bs.modal": function () {
    var zIndex = 1040 + (10 * $(".modal.show").length);
    $(this).css("z-index", zIndex);
    setTimeout(function() {
      $(".modal-backdrop").not(".modal-stack").css("z-index", zIndex - 1).addClass("modal-stack");
    });
  },
  "hidden.bs.modal": function() {
    if ($(".modal.show").length > 0) {
      // restore the modal-open class to the body element, so that scrolling works
      // properly after de-stacking a modal.
      setTimeout(function() {
        $(document.body).addClass("modal-open");
      });
    }
  }
}, ".modal");
